import hero1 from './assets/HW1521126646-WEB-CHALLENGE-BANNER_1600x500_1.jpg';
import week1 from './assets/HW1521126646-WEB-CHALLENGE-WEEK1SIGN2025.jpg';
import week8 from './assets/HW1521126646-WEB-CHALLENGE-WEEK8SIGN2025.jpg';
import hero4 from './assets/HW1521126646-WEB-CHALLENGE-BANNER_1230x459_.jpg';
import sponsor from './assets/IHG-Logos.jpg';
import React, { useContext } from 'react';
import User from './User';
import { ChallengeStage } from './models/challengeStage';
import { ChallengeStageContext } from './context/challengeStageContext';
import './App.css';
import { useOktaAuth } from '@okta/okta-react';
import {
  NotStartedWelcome,
  FinalWelcome,
  FinishedWelcome,
} from './Welcome';
import { Link } from 'react-router-dom';

export const Home: React.FC = () => {
  const now = new Date();
  const context = useContext(ChallengeStageContext(now));
  const { authState } = useOktaAuth();

  return (
    <>
      <header className="App-header">
        {context.currentStage === ChallengeStage.NotStarted && (
          <img src={hero1} className="App-logo" alt="logo" />
        )}
        {context.isInitialVitalsStage && (
          <img src={week1} className="App-logo" alt="logo" />
        )}
        {context.isFinalVitalsStage && (
          <img src={week8} className="App-logo" alt="logo" />
        )}
        {context.currentStage === ChallengeStage.Finished && (
          <img src={hero4} className="App-logo" alt="logo" />
        )}
      </header>
      <User />
      <div className="content content-home">
        {context.currentStage === ChallengeStage.NotStarted ||
        context.canEnterInitialVitals ? (
          <NotStartedWelcome />
        ) : null}

        {context.isFinalVitalsStage ||
        context.currentStage ===
          ChallengeStage.InitVitalsEntryFinished ? (
          <FinalWelcome />
        ) : null}

        {context.currentStage === ChallengeStage.Finished ? (
          <FinishedWelcome />
        ) : null}
        <p>
          If you have any questions please don't hesitate to reach out
          to us on email:
          <a
            className="email"
            href="mailto:info@healthwiseglobal.com"
          >
            info@healthwiseglobal.com
          </a>
        </p>

        <p>GOOD LUCK Challengers!</p>

        <p>
          Massive thanks to IHG and their brands for sponsoring this
          event.
        </p>
        <img src={sponsor} className="App-logo" alt="sponsor" />

        {authState.isAuthenticated ? (
          <div className="logout-link">
            <Link to="/logout" className="App-link">
              Sign out
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};
